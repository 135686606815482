import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import searchLogo from './assets/logo_search.svg';
import { useDebounce } from '@shared/hooks/useDebounce';

const StyledInput = styled.input`
  margin: 29px 20px 0;
  background: var(--light-gray, #f2f2f2);
  padding: 8px 8px 8px 35px;
  position: relative;
  background-image: url(${searchLogo});
  background-repeat: no-repeat;
  background-size: 23px 23px;
  background-position: 7px 3px;
  color: #363942;
  font-size: 13px;
  font-family: Roboto;
  line-height: 16px;
  border-radius: 16px;

  @media screen and (min-width: 1000px) {
    margin: 16px 32px 0 20px;
  }
`;

interface IProps {
  onChange: (v: string) => void;
}

const FilterInput = ({ onChange }: IProps) => {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // Fetch API (optional)
  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <StyledInput
      placeholder={'Поиск'}
      value={value}
      onChange={handleChange}
      autoFocus={document.documentElement.scrollWidth > 1000}
    />
  );
};

export default FilterInput;
