import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceType } from '@shared/types';
import { fetchPOSTData } from 'api';

interface appsState {
  isAppsRefreshing: boolean;
  isInitiatorsArrRefreshing: boolean;
  servicesArr: ServiceType[];
  errorMessage: string;
}

const initialState: appsState = {
  isAppsRefreshing: false,
  isInitiatorsArrRefreshing: false,
  servicesArr: [],
  errorMessage: '',
};

const AppsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setServices(state, action: PayloadAction<ServiceType[]>) {
      return { ...state, servicesArr: action.payload };
    },
    setAppsRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isAppsRefreshing: action.payload };
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload };
    },
  },
});

export const { setServices, setErrorMessage, setAppsRefreshing } = AppsSlice.actions;

export default AppsSlice.reducer;

export const fetchServices = createAsyncThunk('apps/fetchServices', async (_, { dispatch }) => {
  await fetchPOSTData('Services', dispatch, setServices, setErrorMessage);
});
