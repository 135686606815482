import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './AuthPage.module.scss';
import Header from '@components/Header/Header';
import { AuthContext } from '@contexts/CurrUserContext';
import authPic from '@assets/auth/auth_pic.png';
import { SubmitButton } from '@components/UI/Button';
import { handleAuth } from 'api';
import { regExp } from '@shared/constants';
import Preloader from '@components/UI/Preloader/Preloader';
import { Base64 } from 'js-base64';
export default function AuthPage() {
  const { login, handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [err, setErr] = useState({ email: '', pass: '', common: '' });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorType, setErrorType] = useState<number | null>();
  const isDesktop = document.documentElement.scrollWidth < 800;

  useEffect(() => {
    let errRes = { ...err };
    if (errorType === 401) {
      errRes = { ...errRes, common: 'Неправильные почта или пароль' };
    } else if (errorType) {
      errRes = { ...errRes, common: 'На сервере произошла ошибка' };
    } else {
      errRes = { ...errRes, common: '' };
    }
    setErr(errRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  const handleInputChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitDisabled(false);
    let errRes = { ...err };
    errRes = { ...errRes, common: '' };
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
      if (value.length === 0) {
        errRes = { ...errRes, email: 'Поле обязательно к заполнению.' };
      } else if (!regExp.test(value)) {
        errRes = { ...errRes, email: 'Неправильный формат почты.' };
      } else {
        errRes = { ...errRes, email: '' };
      }
    } else if (name === 'password') {
      setPass(value);
      if (value.length === 0) {
        errRes = { ...errRes, pass: 'Поле обязательно к заполнению.' };
      } else {
        errRes = { ...errRes, pass: '' };
      }
    }
    setErr(errRes);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorType(null);
    const AuthData = Base64.encode(`${email}:${pass}`);
    handleAuth(AuthData)
      .then((res) => {
        localStorage.setItem('userInfo', AuthData);
        login(res);
        handleLogin();
        navigate('/');
      })
      .catch((err) => {
        console.error(err);
        setErrorType(typeof err === 'object' ? 500 : err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <main className={styles.main}>
      <div className={styles.deskBack}>
        <div className={styles.container}>
          <span className={styles.logo} />
          {/* <Link to={'/'} className={styles.more}>
            Читать далее
          </Link> */}
        </div>
        <span className={styles.circles} />
      </div>
      {isDesktop && <Header />}
      <div className={styles.info}>
        <form autoComplete="off" noValidate className={styles.form} onSubmit={(e) => onSubmit(e)}>
          <label className={styles.label}>
            <input
              className={cn(styles.input, err.email.length !== 0 && styles.input_error)}
              placeholder="Введите e-mail"
              id="email"
              type="email"
              name="email"
              disabled={isLoading}
              onChange={(e) => handleInputChanger(e)}
              autoFocus
            />
            <p className={styles.title}>Укажите адрес электронной почты</p>
            {err.email.length !== 0 && <span className={styles.span_error}>{err.email}</span>}
          </label>
          <label className={styles.label}>
            <input
              className={cn(styles.input, err.pass.length !== 0 && styles.input_error)}
              placeholder="Введите пароль"
              id="password"
              name="password"
              type={!isPasswordShow ? 'password' : 'text'}
              disabled={isLoading}
              onChange={(e) => handleInputChanger(e)}
            />
            <p className={styles.title}>Введите пароль</p>
            <i
              className={cn(styles.show, isPasswordShow && styles.show_active)}
              onClick={() => setPasswordShow(!isPasswordShow)}
            ></i>
            {err.pass.length !== 0 && <span className={styles.span_error}>{err.pass}</span>}
          </label>
          <div className={styles.submit_container}>
            {err.common.length !== 0 && <span className={styles.submit_error}>{err.common}</span>}
            {isLoading ? (
              <Preloader />
            ) : (
              <SubmitButton
                text={'Войти'}
                disabled={
                  err.pass.length !== 0 ||
                  err.email.length !== 0 ||
                  email.length === 0 ||
                  pass.length === 0 ||
                  isLoading ||
                  submitDisabled
                }
                classDesc={styles.submit}
              />
            )}
          </div>
        </form>
        <img src={authPic} className={styles.image} alt="auth pict" />
      </div>
    </main>
  );
}
