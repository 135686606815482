import { Base64 } from 'js-base64';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { baseUrl } from '@shared/constants';
import { AppDescType, NewAppType, UserInfoType } from '@shared/types';

// обработчик ответа с сервера
const handleReturn = (res: any) => (res.ok ? res.json() : Promise.reject(res.status));

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Basic dXNlcjp1c2Vy',
};

const handlePassSplit = () => {
  const pass = localStorage.getItem('userInfo');
  const decodedPass = pass ? Base64.decode(pass) : ':';
  const splittedPass = decodedPass.split(':');
  return { Login: splittedPass[0], Password: Base64.encode(splittedPass[1]) };
};

// авторизация
export const handleAuth = (v: string) => {
  const decodedPass = Base64.decode(v);
  const splittedPass = decodedPass.split(':');
  return fetch(`${baseUrl}auth`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ Login: splittedPass[0], Password: Base64.encode(splittedPass[1]) }),
  }).then((res) => handleReturn(res));
};

/**
 * простой запрос
 * @param url_text адрес по которому обращаться
 * @param reqBody объект в теле запроса
 * @param method метод, по умолчанию POST
 * @returns
 */
export const fetchSimple = (url_text: string, reqBody: any = {}, method = 'POST') => {
  let bodyObject = { ...handlePassSplit() };
  Object.keys(reqBody).length !== 0 && (bodyObject = { ...bodyObject, ...reqBody });
  return fetch(`${baseUrl}${url_text}`, {
    method,
    headers,
    body: JSON.stringify(bodyObject),
  }).then((res) => handleReturn(res));
};

// отправка POST запроса на сервер
// Users,Applications
export const fetchPOSTData = (
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  successAction: any,
  errorAction: any
): Promise<any> => {
  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(handlePassSplit()),
  })
    .then((res) => handleReturn(res))
    .then((res) => dispatch(successAction(res)))
    .catch((err) => dispatch(errorAction(err.message)));
};

// запрос категорий
export const fetchCategories = (UID: string) => {
  return fetch(`${baseUrl}/Categories`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      UID: UID,
      ...handlePassSplit(),
    }),
  }).then((res) => handleReturn(res));
};

// отправка POST запроса на сервер
// категории, организации
export const fetchDataPost = (url: string, UID: string) => {
  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ UID: UID, ...handlePassSplit() }),
  }).then((res) => handleReturn(res));
};

// Загрузка одного пользователя с сервера
export const handleDownloadUser = (userUID: string, IsInitiator: boolean) => {
  return fetch(`${baseUrl}ProfileData`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      UID: userUID,
      IsInitiator: IsInitiator,
      ...handlePassSplit(),
    }),
  }).then((res) => handleReturn(res));
};
// Редактирование информации о пользователе (IsInitiator true для инициаторов / false для остальных)
export const handleEditUserInfo = (values: UserInfoType, IsInitiator: boolean) => {
  const result = {
    ...handlePassSplit(),
    IsInitiator: IsInitiator,
    UID: values.UID, // UID редактируемого (обязательный)
    FullName: values.FullName,
    Name: values.Name,
    // Organization: values.Organization.UID,
    Department: values.Department.UID,
    Position: values.Position,
    Address: values.Address,
    // Email: values.Email,
    Phone: values.Phone,
  };
  IsInitiator
    ? Object.assign(result, {
        Priority: values.Priority,
        Telegramm: values.Telegramm,
        NotificationResolution: values.NotificationResolution,
        Comment: values.Comment,
        Service: values.Service.UID,
        Category: values.Category.UID,
      })
    : Object.assign(result, { TakeApplications: values.TakeApplications });
  return fetch(`${baseUrl}EditProfileData`, {
    method: 'POST',
    headers,
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};

// Редактирование аватара пользователе (IsInitiator true для инициаторов / false для остальных)
export const handleEditUserAvatar = (Avatar: string, UID: string, IsInitiator: boolean) => {
  return fetch(`${baseUrl}EditProfileData`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      Avatar: Avatar,
      UID: UID,
      IsInitiator: IsInitiator,
      ...handlePassSplit(),
    }),
  }).then((res) => handleReturn(res));
};

// заявки
// загрузка определенной по UID
export const handleDownloadApplicationDesc = (ApplicationUID: string) => {
  return fetch(`${baseUrl}uid`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ ...handlePassSplit(), UID: ApplicationUID }),
  }).then((res) => handleReturn(res));
};
// создать новую
export const handleCreateNewApplication = (item: NewAppType) => {
  const result = {
    ...handlePassSplit(),
    Subject: item.Subject,
    Priority: item.Priority,
    Note: item.Note,
    Status: item.Status,
    Address: item.Address,
    Initiator: item.Initiator.UID,
    Service: item.Service.UID,
  };
  item.Category?.UID && Object.assign(result, { Category: item.Category.UID });
  return fetch(`${baseUrl}new`, {
    method: 'POST',
    headers,
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};
// отредактировать
export const handleEditApplication = (item: AppDescType) => {
  const result = {
    ...handlePassSplit(),
    Status: item.Status, // статус заявки
    Service: item.Service.UID, // UID сервиса
    Category: item.Category.UID, // категория заявки
    Priority: item.Priority, // приоритет заявки
  };
  return fetch(`${baseUrl}edit/${item.UID}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(result),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};
// добавить комментарий
export const handleSendNewComment = (item: any) => {
  return fetch(`${baseUrl}addcomment/${item.UID}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...handlePassSplit(),
      Note: item.Note, // Содержание комментария
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};

// база знаний
export const handleDownloadKnowBase = (v: string) => {
  const result = { ...handlePassSplit() };
  v.length && Object.assign(result, { UID: v });
  return fetch(`${baseUrl}KnowledgeBase${v.length ? '?UID=' + v : ''}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};

// отправить токен для уведомлений
export const handleSendNotificationToken = (item: string) => {
  return fetch(`${baseUrl}token/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...handlePassSplit(),
      Token: item, // токен пользователя
    }),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

// смена пароля
export const handleChangePassword = (item: any, isEditPass: boolean) => {
  return fetch(`${baseUrl}EditPassword`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      Login: item.Mail,
      Password: isEditPass ? Base64.encode(item.OldPassword) : item.OldPassword, // старый пароль
      isNewUser: !isEditPass,
      // NewPassword: item.NewPassword, // Новый пароль
      NewPassword: Base64.encode(item.NewPassword), // Новый пароль
      // OldPassword: isEditPass ? Base64.encode(item.OldPassword) : item.OldPassword, // старый пароль
    }),
  }).then((res) => handleReturn(res));
};

export const handlePostFilesArr = (UID: string) => {
  return fetch(`${baseUrl}Files`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ ...handlePassSplit(), UID }),
  }).then((res) => handleReturn(res));
};

export const handlePostSomeFile = (fileUID: string, appUID: string) => {
  return fetch(`${baseUrl}File`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ ...handlePassSplit(), UID: fileUID, Owner: appUID }),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

export const handleUploadSomeFile = async (file: File, appUID: string) => {
  const fileInfo = file.name.split('.');
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>
    fetch(`${baseUrl}UploadFile`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...handlePassSplit(),
        UID: appUID,
        Type: fileInfo[1],
        Data: reader.result,
        NameFile: fileInfo[0],
      }),
    }).then((res) => (res.ok ? res : console.error(res.status)));
  reader.onerror = function (error) {
    console.error('Error: ', error);
  };
};
