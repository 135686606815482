import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import DatePicker from 'react-datepicker';
// import { registerLocale } from 'react-datepicker';
// import { ru } from 'date-fns/locale';

import { AppDispatch, RootState } from '@redux/store';
import styles from './ModalAppFilter.module.scss';
import { TaskStatus } from '@shared/enums';
import { CategoryType, ServiceType } from '@shared/types';
import 'react-datepicker/dist/react-datepicker.css';
import {
  setFilterFields,
  setFilterModalState,
  resetFiltersFields,
} from '@redux/slices/filterSlices';

import SimpleSearchInput from '@components/UI/SimpleSearchInput';
import { fetchDataPost } from 'api';
import { CategoryName, CategoryShowName, ServiceName, ServiceShowName } from '@shared/constants';

export const ModalAppFilter = () => {
  const { servicesArr } = useSelector((state: RootState) => state.apps);
  const { isOpen, servFilter, categoryFilter, statusFilter, isWithoutExecutor } =
    useSelector((state: RootState) => state.filter);

  const [categoriesArr, setCategoriesArr] = useState<CategoryType[]>([]);
  const [currServ, setCurrServ] = useState<ServiceType>(servFilter);
  const [currCategory, setCurrCategory] = useState<CategoryType>(categoryFilter);
  const [currStatus, setCurrStatus] = useState<TaskStatus[]>(statusFilter);
  // const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
  //   dateRangeFilter[0] ? new Date(dateRangeFilter[0]) : null,
  //   dateRangeFilter[1] ? new Date(dateRangeFilter[1]) : null,
  // ]);
  // const [startDate, endDate] = dateRange;
  // registerLocale('ru', ru);
  const dispatch = useDispatch<AppDispatch>();
  const modalRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    servicesArr.length === 1 && currServ.UID !== servicesArr[0].UID && setCurrServ(servicesArr[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!currServ.UID &&
      fetchDataPost('Categories', currServ.UID)
        .then((res) => {
          if (res.length === 1) setCurrCategory(res[0]);
          setCategoriesArr(res);
        })
        .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currServ.UID]);

  useEffect(() => {
    if (servicesArr.length === 1) handleSetService(servicesArr[0]);
    if (isOpen) {
      setTimeout(() => {
        window.addEventListener('click', onClick);
      }, 100);
    }
    return () => window.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onClick = (e: MouseEvent) => {
    // e.stopImmediatePropagation();
    e.stopPropagation();
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      dispatch(setFilterModalState(false));
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // const startDate = dateRange[0] ? dateRange[0][Symbol.toPrimitive]('number') : 0;
    // const finDate = dateRange[1] ? dateRange[1][Symbol.toPrimitive]('number') : 0;
    dispatch(
      setFilterFields({
        servFilter: currServ,
        categoryFilter: currCategory,
        statusFilter: currStatus,
        // dateRangeFilter: [startDate, finDate],
        dateRangeFilter: [0, 0],
        isWithoutExecutor: isWithoutExecutor,
      })
    );
    dispatch(setFilterModalState(false));
  };

  const handleResetFilter = () => {
    dispatch(resetFiltersFields());
    dispatch(setFilterModalState(false));
  };

  const handleChangeStatusCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value as TaskStatus;
    if (currStatus.includes(value)) {
      setCurrStatus(currStatus.filter((item) => item !== value));
    } else {
      setCurrStatus((prev) => [...prev, value]);
    }
  };

  // const handleSetDateRange = (
  //   date: [Date | null, Date | null],
  //   event: React.SyntheticEvent<any, Event> | undefined
  // ) => {
  //   event?.stopPropagation();
  //   setDateRange(date);
  // };

  const handleSetService = (v: ServiceType) => {
    setCurrCategory({ UID: '', Name: '' });
    setCurrServ(v);
  };

  return (
    <section className={styles.modal} ref={modalRef}>
      <div className={styles.heading}>
        <span className={styles.formBackground}></span>

        <SimpleSearchInput
          currOption={currServ.Name}
          options={servicesArr}
          setCurrOption={(v) => handleSetService(v)}
          styling={{
            margin: '10px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={ServiceName}
          dialogText={ServiceShowName}
          name="Service"
          disabled={false}
        />

        <SimpleSearchInput
          currOption={currCategory.Name}
          options={categoriesArr}
          setCurrOption={(v) => setCurrCategory(v)}
          styling={{
            margin: '0px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={CategoryName}
          dialogText={CategoryShowName}
          disabled={!categoriesArr.length}
          name="Category"
        />
      </div>
      <div className={styles.body}>
        <div className={styles.addition}>
          <p className={styles.dateText}>Статус заявки</p>
          <div>
            {Object.values(TaskStatus).map((item) => (
              <div key={item} className={styles.checkItem}>
                <input
                  type="checkbox"
                  id={item}
                  value={item}
                  onChange={handleChangeStatusCheckbox}
                  checked={currStatus.includes(item)}
                  className={styles.checkbox}
                />
                <label htmlFor={item} className={styles.checkText}>
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={styles.dateItem}>
          <p className={styles.dateText}>Период</p>
          <DatePicker
            // showIcon
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleSetDateRange}
            dateFormat="yyyy-MM-dd"
            locale="ru"
            className={styles.datepicker}
            wrapperClassName={styles.datepickerWrapper}
          />
        </div> */}

        <div className={styles.buttons}>
          <button className={styles.button_reset} onClick={() => handleResetFilter()}>
            Сбросить
          </button>
          <button className={styles.button_submit} onClick={(e) => handleSubmitForm(e)}>
            Показать
          </button>
        </div>
      </div>
    </section>
  );
};
